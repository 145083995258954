import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../styles/styled-components/default-theme';

import '../styles/globals.css';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { NextPage } from 'next';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import Head from 'next/head';

import { AlertsStoreContainer } from '../lib/context/alert/alertContext';
import { ShoppingCartContainer } from '../lib/context/shoppingCart/shoppingCart';
import * as gtag from '../lib/ga/gtag';
import Script from 'next/script';
import { Router, useRouter } from 'next/router';
import { captureException, captureMessage } from '@sentry/browser';
import { AxiosError } from 'axios';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

import NProgress from 'nprogress'; //nprogress module
import 'nprogress/nprogress.css'; //styles of nprogress

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, pageProps: any) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

NProgress.configure({ minimum: 0.1 });

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);

  const router = useRouter();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error) => {
            try {
              if (error instanceof AxiosError) {
                console.log(error);
                console.log(error.response?.status);
                captureException(error);
              } else {
                captureException(error);
              }
            } catch (e) {
              console.log(e);
            }
          },
        }),
        mutationCache: new MutationCache({
          onError: (error) => {
            try {
              if (error instanceof AxiosError) {
                captureException(error);
              } else {
                captureException(error);
              }
            } catch (e) {
              console.log(e);
            }
          },
        }),
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    try {
      const handleRouteChange = (url: any) => {
        gtag.pageview(url);
      };
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    } catch (e) {}
  }, [router.events]);

  return (
    <>
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <Head>
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Head>
      <AlertsStoreContainer>
        <ShoppingCartContainer>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={defaultTheme}>
              {getLayout(<Component {...pageProps} />, pageProps)}
            </ThemeProvider>
          </QueryClientProvider>
        </ShoppingCartContainer>
      </AlertsStoreContainer>
    </>
  );
}
